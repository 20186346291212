const gameSteps = [
	{
		id: 't3-video1',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `I filmen møder I Anja, Gry og Nick, der skal hente deres børn på skolen.`,
		videos: [
			{id: 'AE5rkfG8-iU'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't3-question1',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't3-question1-question'}
		],
		questions: [
			{
				id: 't3-question1-question',
				type: 'multiple-choice',
				text: 'Hvad synes I, der videre skal ske? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'Gry skal tage kontakt til skolen, så hun kan få hjælp til at løse udfordringen. Nick kan tage en snak med Anja og forsøge at hjælpe.',
					},
					{
						id: 2,
						text: 'Nick, hvis barn ikke er en del af konflikten, skal tale med Anja, og forsøge at nedtrappe konflikten mellem forældre.',
					},
				]
			}
		]
	},
	{
		id: 't3-video2',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `En ny samtale mellem forældrene finder sted.`,
		videos: [
			{
				id: 'AJtxetTXIWM', 
				conditions: [{type: 'question-option-selected', questionId: 't3-question1-question', optionId: 1}]
			},
			{
				id: '2fsH0T20jhY', 
				conditions: [{type: 'question-option-selected', questionId: 't3-question1-question', optionId: 2}]
			},
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't3-question2',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't3-question2-question'}
		],
		questions: [
			{
				id: 't3-question2-question',
				type: 'multiple-choice',
				text: 'Hvad synes I, der videre skal ske i filmen? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'Gry skal fortsat forsøge at hjælpe børnene med at løse konflikten ved at samarbejde med skolen og de andre forældre.',
					},
					{
						id: 2,
						text: 'Forældrene skal vente og se tiden an. Indtil nu har deres indgriben kun givet problemer.',
					},
				]
			}
		]
	},
	{
		id: 't3-video3',
		type: 'video',
		title: 'Se videoen. Tryk bagefter videre.',
		text: `Efter et arrangement på skolen, samler Gry og Nick op på samtalen med Anja.`,
		videos: [
			{id: 'jS9cfzSDg4g'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't3-survey1-intro',
		type: 'text',
		title: null,
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Nu kommer der to spørgsmål om jeres individuelle holdninger til emnet.
			<br /><br />Læs spørgsmålet og svar på skift. Lad telefonen gå på omgang.`
	},
	// SURVEY
	{
		id: 't3-survey1',
		type: 'questions',
		layout: 'evaluate-1',
		text: 'Hvad skal vi tænke på, når børnene har behov for støtte til at løse konflikter?',
		subText: 'Tryk hver især på to svar. Tryk så OK og giv telefonen til den næste spiller.',
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		maxAnswers: {
			type: 'all-questions',
			perPlayer: 2
		},
		questions: [
			{
				id: 't3-survey1-q1',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi hjælper børnene med at være nysgerrige på andres perspektiver.'}]
			},
			{
				id: 't3-survey1-q2',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi hjælper børnene med at holde en god tone, når vi taler om andre.'}]
			},
			{
				id: 't3-survey1-q3',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi er nysgerrige på, hvad der ligger bag en uhensigtmæssig handling.'}]
			},
			{
				id: 't3-survey1-q4',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi inddrager ikke børnene i konflikter mellem skole og hjem.'}]
			},
			{
				id: 't3-survey1-q5',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi tror på, at lærerne og pædagogerne løser konflikterne i skolen.'}]
			},
		]
	},
	{
		id: 't3-survey2-intro',
		type: 'text',
		title: null,
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Det var det første spørgsmål. 
			<br /><br />Klik på "Videre" og gå til næste spørgsmål.`
	},
	{
		id: 't3-survey2',
		type: 'questions',
		layout: 'evaluate-2',
		text: `Hvad synes du, der skal tages højde for, når der opstår konflikter mellem børnene?`,
		subText: `Svar ja tak, måske eller nej tak til følgende muligheder. Tryk så OK og giv telefonen videre.`,
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		questions: [
			{
				id: 't3-survey2-q1',
				type: 'evaluate',
				text: 'Som udgangspunkt taler vi sammen i stedet for at skrive sammen.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't3-survey2-q2',
				type: 'evaluate',
				text: 'Vi har en konstruktiv dialog og farer ikke op.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't3-survey2-q3',
				type: 'evaluate',
				text: 'Som udgangspunkt lader vi konflikter, der er opstået i skoletiden, blive løst af skolen.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't3-survey2-q4',
				type: 'evaluate',
				text: 'Vi lytter til læreres og pædagogers vurdering, før vi eventuelt handler.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},{
				id: 't3-survey2-q5',
				type: 'evaluate',
				text: 'Børnene skal selv løse deres konflikter',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			}
		]
	},
	{
		id: 't3-survey1-diskussion',
		type: 'text',
		title: 'Diskussion',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Slut af med kort at diskutere følgende spørgsmål i gruppen:  
		<br /><br />
		Hvad er vigtigt i forhold til konflikter i forældregruppen?
		<br /><br />
		Brug max fem minutter på det og tryk så videre.`
	},
	{
		id: 't3-finish',
		type: 'finish',
		prevBtn: null,
		nextBtn: null,
	}
];
	

export {
	gameSteps
};