const errorTexts = {
	unknownError: 'Fejl: Ukendt fejl.',
	invalidEmail: 'Fejl: Din email er ikke gyldig.',
	emailNotFound: 'Fejl: Der findes ingen bruger med denne email.',
	invalidEmailPassword: 'Fejl: Check om din e-mail og password er korrekte.',
	missingFields: 'Fejl: Husk at udfylde alle felter.',
	differentPasswords: 'Fejl: Passwords er ikke ens',
	gameDoesNotExist: 'Fejl: Der eksisterer ikke noget spil med den spilkode.',
	groupNameTooShort: 'Fejl: Dit gruppenavn skal være på mindst %minChars% karakterer.',
	groupNameTooLong: 'Fejl: Dit gruppenavn må max være på %maxChars% karakterer.',
	groupAlreadyExists: 'Fejl: Der eksisterer allerede en gruppe med dette navn.'
};

const cookieTexts = {
	text1: `Må vi gemme et par cookies? 
		<br /><br />Du kan ikke spille uden. Tryk "JA" for at acceptere dem.`,
	text2: `<br />Vi vil gerne gemme 2 cookies: En der husker, at du har sagt ja til cookies, og en anden, der husker dit login. 
		<br /><br />Trykke "NEJ" for at slette alle cookies og trække dit samtykke tilbage.`,
	seeMore: 'Se mere',
	seeLess: 'Se mindre',
	yes: 'Ja',
	no: 'Nej'
}

const pageTexts = {
	gameStartWelcome: 'Tryk på "Start nyt spil" for at komme i gang.',
	gameStartDescription: 'Vælg om du vil fortsætte i gangværende spil, eller starte et nyt.',
	aboutTheGameTitle: 'Om spillet',
	aboutTheGame: `Spillet faciliterer en samtale om emner, der kan blive vanskelige i forældresamarbejdet. 
	<br />Læs lærevejledningen for at få råd og vejledning til at facilitere spillet.
	<br />Spillet har fire temaer. Vælg et tema for at starte spillet.`
}

const generalTexts = {
	start: 'Start',
	continueGame: 'Fortsæt spil',
	newGame: 'Start nyt spil',
	startGame: 'Start spil', 
	login: 'Log ind',
	logout: 'Log ud',
	loading: 'Loading',
	resetPasswordTitle: 'Gendan password',
	resetPasswordMessage: 'Indtast din e-mail for at nulstille dit password.',
	gameCode: 'Spilkode',
	groupName: 'Gruppenavn',
	selectAGroup: 'Vælg gruppe',
	createGroup: 'Opret ny gruppe',
	newGroup: 'Ny gruppe',
	writeGroupName: 'Skriv gruppenavn',
	howManyPlayers: 'Hvor mange er I i gruppen?',

	email: 'E-mail',
	reset: 'Gendan',
	backToLogin: 'Tilbage til login',
	password: 'Password',
	repeatPassword: 'Gentag password',
	forgottenPassword: 'Glemt password',
	createUser: 'Opret ny bruger',
	backToSettings: 'Tilbage til settings',

	gameTitle: 'Forældremødespillet',
	gameTitleSplit: 'Forældre møde spillet',

	welcome: `Velkommen til forældremødespillet!`,
	instructions: `Del forældrene op i grupper af 2-3 personer. Hver gruppe skal have en telefon for at spille med. Tryk så "videre".`,

	gameStatusTitle: `Grupperne ser nu korte interaktive film og slutter af med at svare på spørgsmål om deres individuelle holdninger. Klik på "Gå til resultater" når alle grupper er igennem forløbet.`,
	waitingForGroups: 'Venter på grupper ...',
	qrText: 'Brug QR-koden til at logge ind.',
	gameCode: 'Spilkode',
	groupWarningText: `En eller flere grupper mangler stadig at gennemføre hele forløbet.
		<br /><br />Er du sikker på, du vil fortsætte?`,
	yesContinue: 'Ja, fortsæt',
	noCancel: 'Nej, afbryd',
	
	no: 'Nej',
	yes: 'Ja',
	evalPopupMessage: 'Du har ikke svaret på alle spørgsmålene? Er du sikker på du vil fortsætte?',
	answers: 'Svar',

	ok: 'OK',
	back: 'Tilbage',
	continue: 'Videre',
	goToResults: 'Gå til resultater',
	about: 'Om spillet',
	furtherDiscussion: 'Lad os lave aftaler!',
	viewResult1: 'Se svar på spørgsmål 1',
	viewResult2: 'Se svar på spørgsmål 2',
	teacherManual: 'Lærervejledning',

	maxAnswersLimit1: 'I må max vælge %perPlayer% svar per gruppemedlem i alt.',
	groupMember: 'Gruppemedlem',

	donePageTitle: 'Godt spillet!',
	donePageText: 'Gå tilbage til klassen.',
}
export default {
	errorTexts: errorTexts,
	pageTexts: pageTexts,
	cookieTexts: cookieTexts,
	generalTexts: generalTexts,
}