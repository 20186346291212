const gameSteps = [
	{
		id: 't2-video1',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `Jon, Mia og Lisa har børn i samme legegruppe. Jon forsøger at tale med Mia, da hendes datter, Olivia, ikke har holdt legegruppe endnu.`,
		videos: [
			{id: 'roRKqmIoseA'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't2-question1',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't2-question1-question'}
		],
		questions: [
			{
				id: 't2-question1-question',
				type: 'multiple-choice',
				text: 'Hvad ville I gøre i sådan et dilemma? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'De skal lade Mia være. De er trods alt tre forældrepar, der afholder legegruppe, og børnene er glade.',
					},
					{
						id: 2,
						text: 'De skal være nysgerrige i snakken Mia. Sammen kan de måske hjælpe med, at Olivia også holder legegruppe.',
					},
				]
			}
		]
	},
	{
		id: 't2-video2',
		type: 'video',
		title: 'Se videoen. Tryk "videre" for at vælge mellem forskellige muligheder.',
		text: `Olivias mor, Mia, tager selv fat i Jon en dag, de begge skal hente børn`,
		videos: [
			{id: 'pJprQrFGD-8'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't2-question2',
		type: 'questions',
		text: 'Diskutér dilemmaet',
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [
			{type: 'question-answered', questionId: 't2-question2-question'}
		],
		questions: [
			{
				id: 't2-question2-question',
				type: 'multiple-choice',
				text: 'Hvad ville I gøre i sådan en situation? I har følgende valgmuligheder:',
				options: [
					{
						id: 1,
						text: 'Der bliver ikke gjort mere. Mias datter, Olivia, deltager i legegruppen uden selv at holde legegruppe.',
					},
					{
						id: 2,
						text: 'De andre forældre skal tilbyde at hjælpe Mia, så Olivia også kan holde legegruppe.',
					},
				]
			}
		]
	},
	{
		id: 't2-video3',
		type: 'video',
		title: 'Se videoen. Tryk bagefter videre.',
		text: `Jon fortæller Lisa om sin snak med Mia`,
		videos: [
			{id: '4oAPjmFmIs8'}
		],
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: []
	},
	{
		id: 't2-survey1-intro',
		type: 'text',
		title: null,
		prevBtn: 'Tilbage til film',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Nu kommer der to spørgsmål om jeres individuelle holdninger til emnet.
			<br /><br />Læs spørgsmålet og svar på skift. Lad telefonen gå på omgang.`
	},
	// SURVEYS
	{
		id: 't2-survey1',
		type: 'questions',
		layout: 'evaluate-1',
		text: 'Hvad skal vi tænke på, når vi som forældre skal styrke fællesskabet i klassen?',
		subText: 'Tryk på to svar. Tryk så OK og giv telefonen til den næste spiller.',
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		maxAnswers: {
			type: 'all-questions',
			perPlayer: 2
		},
		questions: [
			{
				id: 't2-survey1-q1',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'At børnene leger på tværs og ikke altid med de samme.',}]
			},
			{
				id: 't2-survey1-q2',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi forældre er åbne og opmærksomme på, om nogle børn føler sig udenfor fællesskabet.',}]
			},
			{
				id: 't2-survey1-q3',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi forældre har et fælles ansvar for, at alle børn har nogen at lege med.',}]
			},
			{
				id: 't2-survey1-q4',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'Vi forældre bakker op om, at alle må være med i lege og aktiviteter i skole og fritidsordning.',}]
			},
			{
				id: 't2-survey1-q5',
				type: 'evaluate',
				options: [{id: 'option-1', text: 'At styrke fællesskabet behøver ikke at koste penge.',}]
			},
		]
	},
	{
		id: 't2-survey2-intro',
		type: 'text',
		title: null,
		prevBtn: null,
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Det var det første spørgsmål.
			<br /><br />Klik på "Videre" og gå til næste spørgsmål.`
	},
	{
		id: 't2-survey2',
		type: 'questions',
		layout: 'evaluate-2',
		text: `Hvad synes du, der skal tages højde for, hvis I planlægger legegrupper?`,
		subText: 'Svar ja tak, måske eller nej tak til følgende muligheder. Tryk så OK og giv telefonen videre.',
		prevBtn: 'Tilbage',
		nextBtn: 'OK',
		nextBtnConditions: [],
		questions: [
			{
				id: 't2-survey2-q1',
				type: 'evaluate',
				text: 'Vi vil gerne have legegrupper.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't2-survey2-q2',
				type: 'evaluate',
				text: 'Legegruppen tilrettelægges, så alle børn kan deltage.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't2-survey2-q3',
				type: 'evaluate',
				text: 'Legegrupper holdes på et overkommeligt niveau, og det er OK, at ikke alle forældre har overskud til at arrangere.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't2-survey2-q4',
				type: 'evaluate',
				text: 'Legegrupperne laves i samarbejde med læreren.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
			{
				id: 't2-survey2-q5',
				type: 'evaluate',
				text: 'Legegrupperne holdes uden aktiviteter, der koster penge, så alle har mulighed for at deltage.',
				options: [
					{
						id: 'option-1',
						text: 'Ja tak',
					},
					{
						id: 'option-2',
						text: 'Måske',
					},
					{
						id: 'option-3',
						text: 'Nej tak',
					}
				]
			},
		]
	},
	{
		id: 't2-survey1-diskussion',
		type: 'text',
		title: 'Diskussion',
		nextBtn: 'Videre',
		nextBtnConditions: [],
		text: `Slut af med kort at diskutere følgende spørgsmål i gruppen:  
		<br /><br />
		Hvad er vigtigt i forhold til legegrupper? 
		<br /><br />
		Brug max fem minutter på det og tryk så videre.`
	},
	
	{
		id: 't2-finish',
		type: 'finish',
		prevBtn: null,
		nextBtn: null,
	}
];
	

export {
	gameSteps
};